var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "vab-layout-column",
      class: {
        fixed: _vm.fixedHeader,
        "no-tabs-bar": !_vm.showTabs,
      },
    },
    [
      _c("vab-column-bar"),
      _c(
        "div",
        {
          staticClass: "vab-main",
          class: {
            ["vab-main-" + _vm.theme.columnStyle]: true,
            "is-collapse-main": _vm.collapse,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "vab-layout-header",
              class: {
                "fixed-header": _vm.fixedHeader,
              },
            },
            [
              _c("vab-nav"),
              _c("vab-tabs", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showTabs,
                    expression: "showTabs",
                  },
                ],
              }),
            ],
            1
          ),
          _c("vab-app-main"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }